import { runInAction } from 'mobx'

// eslint-disable-next-line import/no-cycle
import { timer } from 'utils'

import BaseStore from './BaseStore'

let state

export class Messager extends BaseStore {
  constructor() {
    super()
    this.observable({ messager: null })

    state = this
  }

  error({ title, message }) {
    const data = {
      type: 'error',
      title,
      message,
      created_at: timer.get(),
    }

    this.createMessage(data)
  }

  success({ title, message }) {
    const data = {
      type: 'success',
      title,
      message,
      created_at: timer.get(),
    }

    this.createMessage(data)
  }

  createMessage(data) {
    setTimeout(() => {
      state.clearMessage()
    }, 3000)

    runInAction(() => {
      state.messager = data
    })
  }

  clearMessage() {
    const { messager } = this.toJS()
    const now = timer.get()
    if (messager) {
      const val = now.diff(messager.created_at, 'seconds')
      if (val > 0) {
        runInAction(() => {
          state.messager = null
        })
      } else {
        setTimeout(() => {
          state.clearMessage()
        }, 0)
      }
    }
  }
}

export default new Messager()
