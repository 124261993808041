import styled from 'styled-components'

import { theme } from 'definition'
import { Find } from 'icon'

import LoadingIcon from './LoadingIcon'

const FindBtn = (props) => {
  const { loading, onClick, disabled } = props

  const icon = loading ? (
    <LoadingIcon loading={true} />
  ) : (
    <Find color={disabled ? theme.gray.line : theme.color.primary} />
  )
  return (
    <Button
      onClick={loading || disabled ? undefined : onClick}
      disabled={disabled}
    >
      {icon}
    </Button>
  )
}

const Button = styled.div`
  width: 72px;
  height: 64px;
  border: 1px solid
    ${(props) =>
      props.disabled ? props.theme.gray.line : props.theme.color.primary};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

export default FindBtn
