import styled from 'styled-components'

import CardExpireInput from 'components/button/CardExpireInput'
import { FieldInput } from 'components/input'
import { CCVInput } from 'components/input/CCVInput'
import CreditCardInput from 'components/input/CreditCardInput'
import { Express, MasterCard, Visa } from 'icon'

const CreditCard = (props) => {
  const { onChange, card, isSubmit } = props

  const validate = (data = {}) => {
    const {
      name = '',
      surname = '',
      expired = '',
      card_id = '',
      cvv = '',
    } = data

    if (name.length === 0) return true
    if (surname.length === 0) return true
    if (expired.length !== 5) return true
    if (cvv.length !== 3) return true

    return card_id.length !== 16
  }

  const onChangeValue = (val, name) => {
    card[name] = val
    card.invalid = validate(card)
    onChange({ ...card })
  }

  const onChangeCard = (obj, name) => {
    card.card_id = obj.card_id
    card.invalid = validate(card)
    onChange({ ...card })
  }

  const onChangeExpire = (obj, name) => {
    card.ex_month = obj.ex_month
    card.ex_year = obj.ex_year
    card.expired = obj.expired
    card.invalid = validate(card)
    onChange({ ...card })
  }

  const isError = card.invalid && isSubmit
  return (
    <>
      <LogoLine>
        <Visa />
        <MasterCard />
        <Express />
      </LogoLine>
      <InputForm>
        <FieldInput
          label="ชื่อ"
          name="name"
          value={card.name}
          onChange={onChangeValue}
        />
        <FieldInput
          label="นามสกุล"
          name="surname"
          value={card.surname}
          onChange={onChangeValue}
        />
        <CreditCardInput
          name="card_id"
          value={card.card_id}
          onChange={onChangeCard}
          error={isError}
        />
        <ExpireForm>
          <CardExpireInput
            name="expired"
            value={card.expired}
            onChange={onChangeExpire}
            error={isError}
          />
          <CCVInput
            name="cvv"
            value={card.cvv}
            onChange={onChangeValue}
            error={isError}
          />
        </ExpireForm>
      </InputForm>
    </>
  )
}

const InputForm = styled.div`
  display: grid;

  margin-bottom: 30px;
  row-gap: 16px;
  grid-template: 1fr / 1fr;
`

const ExpireForm = styled.div`
  display: flex;
  column-gap: 25px;
`

const LogoLine = styled.div`
  display: flex;
  column-gap: 4px;
  margin-bottom: 10px;
`

export default CreditCard
