import styled from 'styled-components'

import { MetaData } from 'components/display'
import { Link } from 'components/link'
import { Text } from 'components/typo'

import BANNER from './assets/404.webp'

const NotFound = (props) => {
  return (
    <AppView>
      <MetaData title="ฟรายเดย์ - Friday" description="ไม่พบข้อมูล" />
      <BannerImg src={BANNER} alt="" />
      <Text size="small" color="#C4D7F2">
        Page not found
      </Text>
      <div style={{ height: 8 }} />
      <CustomLink to="/">
        <Text size="xsmall" primary bold>
          <u>Back to home</u>
        </Text>
      </CustomLink>
    </AppView>
  )
}

export default NotFound

const AppView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 18px;
  text-align: center;
`

const BannerImg = styled.img`
  width: 100%;
  max-width: 440px;
  margin-bottom: 16px;
`

const CustomLink = styled(Link)`
  margin-top: 8px;
`
