import { runInAction } from 'mobx'

// eslint-disable-next-line import/no-cycle
import { checkFacebook, checkGoogle } from 'app/firebaseApp'
import { http, firebase, error } from 'utils'
import { config } from 'config'

import member from './Member'
import BaseStore from './BaseStore'

let state
let check_code

export class Account extends BaseStore {
  constructor() {
    super()
    this.observable({
      agent: {},
      company: {
        company_list: [],
        dropdown_list: [],
      },
      consent: {
        consent_type: '',
        content: '',
      },
      rating: { level: 'none' },
      payment: {},
      plan: {},
      delete_reason_list: [],
      connection: {},
    })

    state = this
  }

  async getCompanyList() {
    const len = this.toJS().company.dropdown_list.length
    if (len > 0) return

    const url = `${config.api}/v1/user/master/company`
    const res = await http.get(url)

    const company_list = res.body || []
    const dropdown_list = company_list.map((it) => {
      return {
        label: it.name,
        value: it.company_id,
      }
    })

    runInAction(() => {
      state.company = { company_list, dropdown_list }
    })
  }

  async getAgent() {
    const url = `${config.api}/v1/user/agent`
    const res = await http.get(url)
    runInAction(() => {
      state.agent = res.body
    })
  }

  async updateAgent(json = {}) {
    const url = `${config.api}/v1/user/agent`
    const res = await http.put(url, { json })

    runInAction(() => {
      state.agent = res.body
    })
  }

  async updateAgentNotification(json = {}) {
    const url = `${config.api}/v1/user/agent/notification`
    await http.put(url, { json })

    const data = this.toJS().agent
    data.one_month = json.one_month
    data.three_month = json.three_month
    data.six_month = json.six_month
    data.nine_month = json.nine_month
    data.twelve_month = json.twelve_month

    runInAction(() => {
      state.agent = data
    })
  }

  setAgent(doc = {}) {
    runInAction(() => {
      state.agent = doc
    })
  }

  async getConsent(consent_type) {
    const { consent } = this.toJS()
    if (consent.consent_type === consent_type) return

    const url = `${config.api}/v1/user/consent/${consent_type}`
    const res = await http.get(url)

    runInAction(() => {
      state.consent = res.body
    })
  }

  async getRating() {
    const url = `${config.api}/v1/user/support/rating`
    const res = await http.get(url)

    runInAction(() => {
      state.rating = res.body
    })
  }

  async updateRating(json = {}) {
    const url = `${config.api}/v1/user/support/rating`
    const res = await http.put(url, { json })

    runInAction(() => {
      state.rating = res.body
    })
  }

  async paymentByCard(json = {}) {
    const url = `${config.api}/v1/user/payment/card`
    const res = await http.post(url, { json })

    return res.body
  }

  async paymentByQRCode(json = {}) {
    const url = `${config.api}/v1/user/payment/qr`
    const res = await http.post(url, { json })

    return res.body
  }

  async getPaymentResult(code) {
    const alter = code ? `/${code}` : ''
    const url = `${config.api}/v1/user/payment/result${alter}`
    const res = await http.get(url)
    const { payment, user } = res.body

    return { payment, user }
  }

  async getMyPayment() {
    const url = `${config.api}/v1/user/payment/my`
    const res = await http.get(url)
    const { payment } = res.body || {}

    runInAction(() => {
      state.payment = payment || {}
    })
  }

  async getMyPlan() {
    const url = `${config.api}/v1/user/plan/my`
    const res = await http.get(url)
    const plan = res.body || {}

    runInAction(() => {
      state.plan = plan || {}
    })
  }

  async getDeleteReason() {
    const url = `${config.api}/v1/user/application/leave/reason`
    const res = await http.get(url)
    const list = res.body || []

    runInAction(() => {
      state.delete_reason_list = list
    })
  }

  async getConnectionList() {
    const url = `${config.api}/v1/user/member/connection`
    const res = await http.get(url)
    const data = res.body || {}

    runInAction(() => {
      state.connection = data
    })
  }

  async disconnect(action) {
    const url = `${config.api}/v1/user/member/connection/remove/${action}`
    const res = await http.put(url, { json: {} })
    const data = res.body || {}

    runInAction(() => {
      state.connection = data
    })
  }

  async connectGmail() {
    const { data } = await checkGoogle()
    if (data) {
      const { uid, email } = data
      const json = {
        uid,
        email,
      }
      const url = `${config.api}/v1/user/member/connection/add/gmail`
      const res = await http.put(url, { json })
      const connection = res.body || {}

      runInAction(() => {
        state.connection = connection
      })
    }
  }

  async connectFacebook() {
    const { data } = await checkFacebook()
    if (data) {
      const { uid, facebook_id } = data
      const json = {
        uid,
        facebook_id,
      }

      const url = `${config.api}/v1/user/member/connection/add/facebook`
      const res = await http.put(url, { json })
      const connection = res.body || {}
      runInAction(() => {
        state.connection = connection
      })
    }
  }

  async sendConfirmEmail(email, confirm_type) {
    const json = { email, confirm_type }
    const url = `${config.api}/v1/user/member/connection/add/email`
    const res = await http.put(url, { json })
    return res.body
  }

  async getVerifyCode(c) {
    const url = `${config.api}/v1/user/member/connection/verification/${c}`
    const res = await http.get(url)
    const verify = res.body || {}

    return verify
  }

  async confirmChangeEmail({ ref_code, code }) {
    const key = `${ref_code}-${code}`
    if (check_code === key) {
      return
    }

    check_code = key
    const url = `${config.api}/v1/public/member/connection/confirm/${ref_code}/${code}`
    const res = await http.put(url, { json: {} })
    const { token, user, connection } = res.body || {}
    const { user: fbUser } = await firebase.auth().signInWithCustomToken(token)
    error.isNull(user)

    const token_id = await fbUser.getIdToken()
    await http.setToken(token_id)

    member.setUserRegister({ user })

    runInAction(() => {
      state.connection = connection
    })
  }

  async sendOtp(phone) {
    const url = `${config.api}/v1/user/otp/generate`
    const res = await http.post(url, { json: { phone } })
    const { token } = res.body

    return token
  }

  async verifyOtp(token, otp_code) {
    const url = `${config.api}/v1/user/otp/verify`
    await http.post(url, { json: { token, otp_code } })
  }

  async connectMobile(mobile) {
    const url = `${config.api}/v1/user/member/connection/add/mobile`
    const res = await http.put(url, { json: { mobile } })
    const connection = res.body || {}

    runInAction(() => {
      state.connection = connection
    })
  }

  async verifyOldPassword(email, oldPassword) {
    const auth = firebase.auth()
    await auth.signInWithEmailAndPassword(email, oldPassword)
  }

  async updatePassword(password) {
    const user = firebase.auth().currentUser
    await user.updatePassword(password)
  }
}

export default new Account()
