import { inject, observer } from 'mobx-react'
import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from 'components/button'
import { Text } from 'components/typo'
import { message } from 'utils'

const UnsubscribeEmail = (props) => {
  const location = useLocation().search.split('=')[1]
  const val = { token: location }
  const history = useHistory()

  const onInit = useCallback(async () => {
    try {
      await props.member.unsubscribe(val)
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
  }, [props.member])

  useEffect(() => {
    onInit()
  }, [onInit])

  return (
    <Wrapper>
      <Text size="large" title bold>
        ยกเลิกการแจ้งเตือนอีเมลล์สำเร็จแล้ว
      </Text>
      <Button large color="primary" onClick={() => history.push('/')}>
        กลับไปหน้าหลัก
      </Button>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  row-gap: 16px;
  height: 80vh;
`

export default inject('member')(observer(UnsubscribeEmail))
