import { runInAction } from 'mobx'

// eslint-disable-next-line import/no-cycle
import { helper } from 'utils'

import BaseStore from './BaseStore'

let state

export class Report extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: {},
    })

    state = this
  }

  async get() {
    //const url = `${config.api}/v1/user/promotion/${code}`
    //const res = await http.get(url)
    //return res.body
    await helper.sleep(10000)

    runInAction(() => {
      state.doc = {
        name: 'Hello World',
        liquid_asset_list: [
          {
            name: 'เงินสด',
            value: 200010.5,
          },
          {
            name: 'เงินฝากบัญชีธนาคาร เงินฝากบัญชีธนาคาร เงินฝากบัญชีธนาคาร สลากออมสิน',
            value: 203005,
          },
          {
            name: 'สลากออมสิน',
            value: 1252000,
          },
          {
            name: 'สะสมให้ลูก',
            value: 500500,
          },
        ],
      }
    })
  }
}

export default new Report()
