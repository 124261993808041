import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

// eslint-disable-next-line import/no-cycle
import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  consent: {
    ver: '',
    content: '',
  },
}

let state

export class AppConfig extends BaseStore {
  constructor() {
    super()
    this.observable({
      privacy: cloneDeep(original.consent),
      terms: cloneDeep(original.consent),
    })

    state = this
  }

  toPrivacyConsent() {
    return this.toJS().privacy
  }

  toTermsConsent() {
    return this.toJS().terms
  }

  async getPrivacyConsent() {
    const doc = this.toPrivacyConsent()
    if (doc.consent_id) return doc

    const url = `${config.api}/v1/public/consent/privacy`
    const resp = await http.get(url)

    const data = resp.body

    runInAction(() => {
      state.privacy = data
    })

    return data
  }

  async getTermsConsent() {
    const doc = this.toTermsConsent()
    if (doc.consent_id) return doc

    const url = `${config.api}/v1/public/consent/terms`
    const resp = await http.get(url)

    const data = resp.body
    runInAction(() => {
      state.terms = data
    })

    return data
  }
}

export default new AppConfig()
