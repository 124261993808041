import { inject, observer } from 'mobx-react'
import { useCallback, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'components/button/Button'
import { config } from 'config'
import useGTM from 'hooks/useGTM'
import { Logo } from 'icon'

import BANNER from './assets/friday_banner.webp'
import STAR from './assets/star.svg'

function Banner(props) {
  const history = useHistory()
  const { onEvent } = useGTM()

  const isLogin = props.member.isLogin()

  const onStartClick = useCallback(() => {
    onEvent({
      pageName: 'home',
      eventName: 'banner-start-button-click',
    })
    if (isLogin) {
      if (isMobile) {
        window.open(config.app_url, '_blank')
      } else {
        const packageEle = document.getElementById('package')
        packageEle.scrollIntoView({ behavior: 'smooth' })
        history.push('/package')
      }
    } else {
      history.push('/register')
    }
  }, [isLogin, history, onEvent])

  const descriptionText = useMemo(() => {
    if (isMobile) {
      return (
        <>
          จัดการข้อมูลลูกค้าง่ายขึ้นไม่กี่คลิก
          <br />
          บริหารงานขายประกันอย่างมีประสิทธิภาพ
          <br />
          ลดขั้นตอนการสรุปกรมธรรม์ที่แสนน่าเบื่อ
          <br />
          สร้างโอกาสในการขายแบบไม่รู้จบด้วยเครื่องมือวางแผน
          <br />
          การเงินที่ใช้งานง่ายและครอบคลุม
        </>
      )
    }
    return (
      <>
        จัดการข้อมูลลูกค้าง่ายขึ้นไม่กี่คลิก
        บริหารงานขายประกันอย่างมีประสิทธิภาพ
        ลดขั้นตอนการสรุปกรมธรรม์ที่แสนน่าเบื่อ
        <br />
        สร้างโอกาสในการขายแบบไม่รู้จบด้วยเครื่องมือวางแผนการเงินที่ใช้งานง่ายและครอบคลุม
      </>
    )
  }, [])

  const mainText = useMemo(() => {
    if (isMobile) {
      return (
        <b>
          friday เลขาดิจิทัล
          <br />
          ของตัวแทนประกันมืออาชีพ
        </b>
      )
    }
    return <b>friday เลขาดิจิทัลของตัวแทนประกันมืออาชีพ</b>
  }, [])

  const btnText = useMemo(() => {
    if (!isMobile && isLogin) {
      return 'เริ่มใช้งาน'
    }
    return 'เริ่มใช้งานฟรี'
  }, [isLogin])

  return (
    <Container>
      <Logo />
      <LogoEffect>
        <div />
        <img src={STAR} alt="star" />
      </LogoEffect>
      <Text>
        Empowering <span>to the best</span>
      </Text>
      <BannerImg src={BANNER} alt="Friday show dashboard." />
      <Description>{descriptionText}</Description>
      <MainText>{mainText}</MainText>
      <Button
        large
        color="primary"
        maxWidth="220px"
        text={btnText}
        onClick={onStartClick}
      />
    </Container>
  )
}

export default inject('register', 'member')(observer(Banner))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px 16px 56px;

  background: linear-gradient(
    180deg,
    rgba(228, 218, 255, 0.4) 0%,
    rgba(228, 218, 255, 0) 59.31%
  );

  > svg {
    width: 177px;
    height: 66px;
    margin-bottom: 4px;
  }
`
const LogoEffect = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  align-items: center;
  gap: 4px;
  width: 263px;
  height: 19px;
  margin: 0 auto 4px;

  > div {
    width: 240px;
    height: 2px;
    background-color: #5f2eea;
    animation: driveThru 1s ease-out;
  }
  > img {
    width: 19px;
    height: 19px;
    opacity: 1;
    animation: visible 1s linear, bounceIn 1s linear 1s;
  }

  @keyframes visible {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes bounceIn {
    0%,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  @keyframes driveThru {
    0% {
      width: 0px;
    }
    100% {
      width: 240px;
    }
  }
`
const Text = styled.h2`
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  color: #5f2eea;

  > span {
    color: #fdc851;
  }
`
const BannerImg = styled.img`
  width: 100%;
  max-width: 440px;
  margin-bottom: 40px;
`
const Description = styled.p`
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 28px;
  color: #4e4b66;
  text-align: center;
`
const MainText = styled.p`
  margin-bottom: 56px;
  font-size: 18px;
  line-height: 24px;
  color: #5f2eea;
  text-align: center;
`
