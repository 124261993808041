import { runInAction } from 'mobx'
import Joi from 'joi'
import CryptoJS from 'crypto-js'

// eslint-disable-next-line import/no-cycle
import { checkFacebook, checkGoogle } from 'app/firebaseApp'
import { error, firebase, http, storage, helper } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const noUserMsg = 'ไม่พบสมาชิกอยู่ในระบบ'

let state

export class Member extends BaseStore {
  constructor() {
    super()
    this.observable({
      verifying: false,
      user: null,
      firebase: {},
      email: '',
      isAccepted: false,
      consentList: [],
    })

    state = this
    http.setMember(this)
  }

  async getUser() {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          resolve(user)
        } else {
          resolve(null)
        }
      })
    })
  }

  async addUserEvent() {
    runInAction(() => {
      state.verifying = true
    })

    await this.getUser()
    await this.getUserProfile()

    runInAction(() => {
      state.verifying = false
    })
  }

  reset() {
    firebase.auth().signOut()

    runInAction(() => {
      state.verifying = false
      state.user = null
      state.firebase = {}
    })
  }

  getCurrentUser() {
    return state.toJS().user
  }

  async checkLoginUser(token) {
    runInAction(() => {
      state.verifying = true
    })

    try {
      if (token) {
        await this.getUserProfile(token)
      } else {
        const user = await this.getUser()
        if (user) {
          runInAction(() => {
            state.firebase = user
          })
          await this.getUserProfile()
        }
      }
    } catch (e) {
      console.log('check user:', e.message)
    }

    runInAction(() => {
      state.verifying = false
    })
  }

  getProvider(params) {
    const { user } = params
    const list = user.providerData || []
    return list.map((item) => item.providerId)
  }

  setUserProfile(params) {
    const { user } = params
    if (user) {
      runInAction(() => {
        state.user = user
      })
    }
  }

  async getUserProfile(t) {
    const token = t ? t : await firebase.auth().currentUser?.getIdToken()
    http.setToken(token)

    const url = `${config.api}/v1/user/profile`
    const resp = await http.get(url)

    const data = resp.body
    const uid = firebase.auth().currentUser?.uid
    const encryptedUid = helper.encrypt(uid)
    storage.save('user', encryptedUid)

    this.setUserProfile({ user: data })
  }

  isLogin() {
    return this.toJS().user !== null
  }

  async logout() {
    firebase.auth().signOut()
    storage.remove('user')
    http.setToken()
    this.reset()
  }

  validateEmail(email) {
    return Joi.string()
      .trim()
      .email({ tlds: { allow: false } })
      .required()
      .validate(email)
  }

  async loginByEmail(params = {}) {
    let { email, password, isNotSave } = params
    const validate = this.validateEmail(email)
    if (validate.error) {
      error.lunch({ message: `This's email ${validate.value} not found` })
      return
    }

    const auth = firebase.auth()

    await auth.signInWithEmailAndPassword(validate.value, password)
    const user = auth.currentUser
    error.isNull(user, { message: noUserMsg })

    await this.getUserProfile()

    if (isNotSave) {
      // await auth.setPersistence('none')
      this.removeLocalPassword()
    } else {
      this.saveLocalPassword({ email, password })
    }
  }

  async loginByFacebook() {
    const { user } = await checkFacebook()
    error.isNull(user, { message: noUserMsg })
    return this.getUserProfile()
  }

  async loginByGoogle() {
    const { user } = await checkGoogle()
    error.isNull(user, { message: noUserMsg })
    return await this.getUserProfile()
  }

  async resendEmail(params = {}) {
    const { ref_code } = params
    const url = `${config.api}/v1/public/user/resend/${ref_code}`
    await http.put(url)
  }

  async registerByEmail(params = {}) {
    let { email = '', password, name, surname } = params
    await this.logout()

    const validate = await this.validateEmail(email)
    error.isError(!!validate.error, {
      message: `Invalid email ${validate.value}`,
    })

    const json = {
      email,
      password,
      name,
      surname,
    }

    const url = `${config.api}/v1/public/register/email`
    const resp = await http.post(url, { json })
    const { status, verification } = resp.body

    error.isError(status === 'used', {
      message: 'อีเมลนี้ถูกใช้แล้ว',
    })

    return verification
  }

  setUserRegister(data = {}) {
    const { user, status } = data

    error.isError(status === 'used', { message: 'บัญชีนี้เป็นสมาชิกอยู่แล้ว' })

    this.setUserProfile({ user })
  }

  async registerByFacebook() {
    const { token, data = {}, error: err, user } = await checkFacebook()

    if (err) {
      return { error: err, data }
    }

    error.isNull(user, { message: noUserMsg })

    const url = `${config.api}/v1/public/register/facebook`

    const resp = await http.post(url, { json: data })

    await http.setToken(token)
    const userData = resp.body.user
    this.setUserRegister({ user: userData })

    return {}
  }

  async registerByGoogle() {
    const { token, data = {}, user } = await checkGoogle()
    error.isNull(user, { message: noUserMsg })

    const url = `${config.api}/v1/public/register/google`
    const resp = await http.post(url, { json: data })

    await http.setToken(token)
    const userData = resp.body.user
    this.setUserRegister({ user: userData })
  }

  async resetPassword({ email }) {
    const json = { email }
    const url = `${config.api}/v1/public/user/reset/password`
    await http.put(url, { json })
  }

  async setNewPasswordByUser({ password }) {
    const json = { password }
    const url = `${config.api}/v1/user/profile/set/password`
    await http.put(url, { json })
  }

  // Register Flow
  async verifyRegisterCode({ code, ref_code }) {
    const url = `${config.api}/v1/public/user/check/code`
    const json = { code, ref_code }
    return await http.put(url, { json })
  }

  async setPassword({ password, code, ref_code }) {
    const url = `${config.api}/v1/public/user/confirm/email`
    const json = { code, ref_code, password }
    const resp = await http.put(url, { json })
    const { token, user } = resp.body

    const { user: fbUser } = await firebase.auth().signInWithCustomToken(token)
    error.isNull(user)

    const token_id = await fbUser.getIdToken()
    await http.setToken(token_id)
    this.setUserRegister({ user })

    runInAction(() => {
      state.firebase = user
    })
  }

  async setNewPassword({ password, code, ref_code }) {
    const url = `${config.api}/v1/public/user/set/password`
    const json = { code, ref_code, password }
    return await http.put(url, { json })
  }

  async updateProfile(json = {}) {
    const url = `${config.api}/v1/user/profile`
    const res = await http.put(url, { json })

    const data = res.body
    const { user = {} } = this.toJS()
    user.img_url = data.img_url
    user.name = data.name
    user.surname = data.surname
    user.mobile = data.mobile
    user.birthday_at = data.birthday_at
    user.email = data.email

    runInAction(() => {
      state.user = user
    })
  }

  saveLocalPassword(obj = {}) {
    const text = JSON.stringify(obj)
    const secret = CryptoJS.AES.encrypt(text, config.key).toString()
    storage.save('secret', { secret })
  }

  removeLocalPassword() {
    storage.remove('secret')
  }

  getLocalPassword() {
    const { secret } = storage.load('secret') || {}
    if (secret) {
      const bytes = CryptoJS.AES.decrypt(secret, config.key)
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

      return data
    }

    return null
  }

  //Call API for unsub
  async unsubscribe(json = {}) {
    const url = `${config.api}/v1/public/user/unsubscribe/email`
    await http.put(url, { json })
  }

  async deleteAccount(json = {}) {
    const url = `${config.api}/v1/user/application/leave`
    const res = await http.put(url, { json })

    const data = res.body

    runInAction(() => {
      state.user = data
    })
  }

  async restoreAccount(json = {}) {
    const url = `${config.api}/v1/user/application/comeback`
    const res = await http.put(url, { json })

    const data = res.body

    runInAction(() => {
      state.user = data
    })
  }

  async updatePlan(json = {}) {
    const url = `${config.api}/v1/user/plan`
    const res = await http.put(url, { json })

    const data = res.body

    runInAction(() => {
      state.user = data
    })
  }

  async checkConsent(cb = () => {}) {
    const url = `${config.api}/v1/user/consent/check`
    const res = await http.get(url)
    const { consent_list = [], is_accepted = false } = res.body

    runInAction(() => {
      state.isAccepted = is_accepted
      state.consentList = consent_list
    })

    cb()
  }

  async acceptConsent(cb = () => {}) {
    const url = `${config.api}/v1/user/consent/accept`
    await http.put(url)
    runInAction(() => {
      state.isAccepted = true
      state.consentList = []
    })
    cb()
  }
}

export default new Member()
