// eslint-disable-next-line import/no-cycle
import store from 'stores'

export class Message {
  error({ title, message = 'ขออภัยระบบเกิดข้อผิดลพลาด' } = {}) {
    store.messager.error({
      title,
      message,
    })
  }

  warning({ title, message } = {}) {
    store.messager.warning({
      title,
      message,
    })
  }

  success({ title, message } = {}) {
    store.messager.success({
      title,
      message,
    })
  }
}

export const message = new Message()
export default message
