// eslint-disable-next-line import/no-cycle
import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

export class Account extends BaseStore {
  constructor() {
    super()
    this.observable({
      reason_list: [],
    })
  }

  async preRegister(json = {}) {
    const url = `${config.api}/v1/public/pre-register`
    await http.post(url, { json })
  }
}

export default new Account()
