import { inject, observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from 'components/button'
import { MetaData } from 'components/display'
import PackageModal from 'components/modal/PackageModal'
import PackageData from 'components/package_data/PackageData'
import PackageMonth from 'components/package_month/PackageMonth'
import PackageYear from 'components/package_year/PackageYear'
import TabButton from 'components/tab/TabButton'
import { config } from 'config'
import useGTM from 'hooks/useGTM'
import useMediaQuery from 'hooks/useMediaQuery'
import Layout from 'pages/account/components/Layout'
import { message } from 'utils'

const PackageDetail = (props) => {
  const history = useHistory()
  const [selected, setSelect] = useState('trial')
  const [selectedPackageFree, setSelectedPackagetFree] = useState(true)
  const [selectedPackageStarter, setSelectedPackagetStarter] = useState(false)
  const [isSavePass, setIsSavePass] = useState('per_year')
  const [isFreeClick, setFreeClick] = useState(false)
  const [isOpened, setOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width:768px)')
  const { headerConfig, rows } = props.package_app.toJS()
  const [counter, setCounter] = useState(30)
  const { onEvent } = useGTM()

  const FreePackage = {
    title: <>แพ็กเกจฟรี</>,
    body: (
      <>
        กำลังพาคุณไปที่เว็บไซต์ใน {counter} วินาที เพื่อเริ่มใช้งาน
        <br />
        คุณสามารถปรับเปลี่ยนแพ็กเกจเป็นแผนอื่นๆได้ในภายหลัง
        <br />
      </>
    ),
  }
  const CostPackage = {
    title: (
      <>
        คุณได้รับสิทธิ
        <br />
        ทดลองใช้ฟรี 30 วัน
      </>
    ),
    body: <>เมื่อครบ 30 วันแล้ว ระบบจะปรับเป็นแพ็กเกจที่คุณเลือก</>,
  }

  const onOk = async () => {
    try {
      if (isFreeClick) {
        setCounter(30)

        history.push('/account/profile')
      }

      window.open(config.app_url, '_blank')
    } catch (e) {
      message.rror({ message: e.message })
    }
  }

  const goPayment = async () => {
    try {
      history.push('/account/payment-card')
    } catch (e) {
      message.error({ message: e.message })
    }
  }

  // const onConfirm = async (packageId) => {
  //   try {
  //     const change_package = await props.payment.getChangePackage(
  //       packageId,
  //       isSavePass
  //     )
  //     const { status } = change_package
  //     if (status === 'no_change') {
  //       message.error({ message: 'ไม่สามารถเปลี่ยนแพ็กเกจได้' })
  //       return
  //     } else if (status === 'pay_more') {
  //       const path = '/account/my-payment/change-package/payment'
  //       return history.push(`${path}/${packageId}/${isSavePass}/info`)
  //     }

  //     await props.payment.changePackage(packageId, isSavePass, {})
  //     message.success({ message: 'เปลี่ยนแพ็กเกจสำเร็จ' })
  //   } catch (e) {
  //     message.error({ message: e.message })
  //   }
  // }

  const openModal = () => setOpen(true)

  useEffect(() => {
    if (isOpened === false) {
      setCounter(30)
    }
    const time = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => {
      clearInterval(time)
    }
  }, [isOpened, counter])

  useEffect(() => {
    props.package_app.getPackageList({
      topButton: true,
      planPeriod: isSavePass,
    })
  }, [props.package_app, isSavePass])

  const modalContent = isFreeClick ? FreePackage : CostPackage

  useEffect(() => {
    if (isOpened === false) {
      setCounter(30)
    } else if (isOpened === true) {
      setCounter(30)
    }
  }, [isOpened])

  useEffect(() => {
    if (counter === 0) {
      setOpen(false)
      history.push('/account/profile')
    }
  }, [history, counter])

  const onClick = (value) => {
    setSelect(value)
  }

  const onClickFree = () => {
    onEvent({
      pageName: 'create-account',
      eventName: 'create-profile-free-package-select-button-click',
    })
    setFreeClick(true)
    openModal()
  }

  const onClickPackage = (packageId) => async () => {
    onEvent({
      pageName: 'create-account',
      eventName: 'create-profile-package-select-button-click',
      params: {
        package: isSavePass === 'per_year' ? 'yearly' : 'monthly',
      },
    })
    try {
      await props.package_app.setPackage({ packageId, planPeriod: isSavePass })

      if (headerConfig.column[2].id === packageId) {
        return goPayment()
        // return onConfirm(packageId)
      }
    } catch (e) {
      message.error({ message: e.message })
    }
  }

  return (
    <Layout header="เลือกแพ็กเกจสำหรับใช้งาน" subHeader="ขั้นตอนที่ 3 จาก 4">
      <MetaData
        title="เลือกแพ็กเกจสำหรับใช้งาน"
        description="เลือกแพ็กเกจสำหรับใช้งาน"
      />
      <PackageModal
        custom={true}
        onOk={() => onOk(isFreeClick)}
        isOpened={isOpened}
        setOpen={setOpen}
        {...modalContent}
      />
      {headerConfig.column?.length > 0 && (
        <Body>
          {isMobile ? (
            <PackageMobileDiv>
              <PackagePageMobile PackageFree={selectedPackageFree}>
                <HeaderPackageMobile>
                  <div className="PackageFree">
                    <TabButton
                      key={'trial'}
                      onClick={() => {
                        setSelectedPackagetStarter(false)
                        setSelectedPackagetFree(true)
                        onClick('trial')
                      }}
                      selected={'trial' === selected}
                    >
                      แพ็คเก็จฟรี
                    </TabButton>
                  </div>
                  <div className="PackageStarter">
                    <TabButton
                      key={'starter'}
                      onClick={() => {
                        setSelectedPackagetFree(false)
                        setSelectedPackagetStarter(true)
                        onClick('starter')
                      }}
                      selected={'starter' === selected}
                    >
                      แพ็คเก็จเริ่มต้น
                    </TabButton>
                  </div>
                </HeaderPackageMobile>
                {selectedPackageFree ? (
                  <>
                    <TimePackageMobile>
                      100 ข้อมูลรายชื่อลูกค้า
                    </TimePackageMobile>
                    <div className="firstColumn">
                      {Object.entries(rows).map(([key, value], index) => {
                        if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                          return (
                            <div key={index}>
                              <PackageDataHeader primary>
                                {key}
                              </PackageDataHeader>
                              {Object.entries(value).map(
                                ([keyIn, valueIn], jndex) => {
                                  if (valueIn.free === true) {
                                    return (
                                      <PackageData key={jndex}>
                                        {keyIn}
                                      </PackageData>
                                    )
                                  }
                                  return <></>
                                }
                              )}
                            </div>
                          )
                        }
                        return <></>
                      })}
                    </div>
                    <div className="secondColumn">
                      {Object.entries(rows).map(([key, value], index) => {
                        if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                          return (
                            <div key={index}>
                              <PackageDataHeader primary>
                                {key}
                              </PackageDataHeader>
                              {Object.entries(value).map(
                                ([keyIn, valueIn], jndex) => {
                                  if (valueIn.free === true) {
                                    return (
                                      <PackageData key={jndex}>
                                        {keyIn}
                                      </PackageData>
                                    )
                                  }
                                  return <></>
                                }
                              )}
                            </div>
                          )
                        }
                        return <></>
                      })}
                    </div>
                    <div className="button">
                      <Button
                        large
                        color="secondary"
                        text="เลือก"
                        width="360px"
                        onClick={onClickFree}
                      />
                    </div>
                  </>
                ) : selectedPackageStarter ? (
                  <>
                    <TimePackageMobile>
                      <b>ไม่จำกัด</b> ข้อมูลรายชื่อลูกค้า
                    </TimePackageMobile>
                    <div className="firstColumn">
                      {Object.entries(rows).map(([key, value], index) => {
                        if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                          return (
                            <div key={index}>
                              <PackageDataHeader primary>
                                {key}
                              </PackageDataHeader>
                              {Object.entries(value).map(
                                ([keyIn, valueIn], jndex) => {
                                  if (valueIn.free === true)
                                    return (
                                      <PackageData key={jndex}>
                                        {keyIn}
                                      </PackageData>
                                    )
                                  else if (valueIn.standard === true) {
                                    return (
                                      <PackageData key={jndex} color>
                                        {keyIn}
                                      </PackageData>
                                    )
                                  }
                                  return <></>
                                }
                              )}
                            </div>
                          )
                        }
                        return <></>
                      })}
                    </div>
                    <div className="secondColumn">
                      {Object.entries(rows).map(([key, value], index) => {
                        if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                          return (
                            <div key={index}>
                              <PackageDataHeader primary>
                                {key}
                              </PackageDataHeader>
                              {Object.entries(value).map(
                                ([keyIn, valueIn], jndex) => {
                                  if (valueIn.free === true)
                                    return (
                                      <PackageData key={jndex}>
                                        {keyIn}
                                      </PackageData>
                                    )
                                  else if (valueIn.standard === true) {
                                    return (
                                      <PackageData key={jndex} color>
                                        {keyIn}
                                      </PackageData>
                                    )
                                  }
                                  return <></>
                                }
                              )}
                            </div>
                          )
                        }
                        return <></>
                      })}
                    </div>
                    <div className="thirdColumn">
                      {Object.entries(rows).map(([key, value], index) => {
                        if (key === 'การทำงานแบบทีม') {
                          return (
                            <div key={index}>
                              <PackageDataHeader primary>
                                {key}
                              </PackageDataHeader>
                              {Object.entries(value).map(
                                ([keyIn, valueIn], jndex) => {
                                  if (valueIn.free === true)
                                    return (
                                      <PackageData key={jndex}>
                                        {keyIn}
                                      </PackageData>
                                    )
                                  else if (valueIn.standard === true) {
                                    return (
                                      <PackageData key={jndex} color>
                                        {keyIn}
                                      </PackageData>
                                    )
                                  }
                                  return <></>
                                }
                              )}
                            </div>
                          )
                        }
                        return <></>
                      })}
                    </div>
                    <PackageYear
                      isSavePass={isSavePass}
                      setIsSavePass={setIsSavePass}
                    />
                    <PackageMonth
                      isSavePass={isSavePass}
                      setIsSavePass={setIsSavePass}
                    />
                    <div className="button">
                      <Button
                        large
                        color="secondary"
                        text="เลือก"
                        width="360px"
                        onClick={onClickPackage(headerConfig?.column[2].id)}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </PackagePageMobile>
            </PackageMobileDiv>
          ) : (
            <PackageDiv>
              <PackagePage>
                <HeaderPackage>แพ็กเกจฟรี</HeaderPackage>
                <TimePackage>100 ข้อมูลรายชื่อลูกค้า</TimePackage>
                <div className="firstColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free === true) {
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <div className="secondColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free === true) {
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <div className="button">
                  <Button
                    large
                    color="secondary"
                    text="เลือก"
                    width="360px"
                    onClick={onClickFree}
                  />
                </div>
              </PackagePage>

              <PackagePage>
                <HeaderPackage>แพ็กเกจเริ่มต้น</HeaderPackage>
                <TimePackage>
                  <b>ไม่จำกัด</b> ข้อมูลรายชื่อลูกค้า
                </TimePackage>
                <div className="firstColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free === true)
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              else if (valueIn.standard === true) {
                                return (
                                  <PackageData key={jndex} color>
                                    {keyIn}
                                  </PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>

                <div className="secondColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free === true)
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              else if (valueIn.standard === true) {
                                return (
                                  <PackageData key={jndex} color>
                                    {keyIn}
                                  </PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>

                <div className="thirdColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การทำงานแบบทีม') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free === true)
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              else if (valueIn.standard === true) {
                                return (
                                  <PackageData key={jndex} color>
                                    {keyIn}
                                  </PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>

                <PackageYear
                  isSavePass={isSavePass}
                  setIsSavePass={setIsSavePass}
                />
                <PackageMonth
                  isSavePass={isSavePass}
                  setIsSavePass={setIsSavePass}
                />
                <div className="button">
                  <Button
                    large
                    color="secondary"
                    text="เลือก"
                    width="360px"
                    onClick={onClickPackage(headerConfig?.column[2].id)}
                  />
                </div>
              </PackagePage>
            </PackageDiv>
          )}
        </Body>
      )}
    </Layout>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  row-gap: 24px;
`
const PackageDiv = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
  .firstColumn {
    max-height: 500px;
    height: 500px;
  }
  .secondColumn {
    margin-top: 40px;
  }
  .button {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .thirdColumn {
    margin-top: 20px;
  }
`

const PackageMobileDiv = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
  .firstColumn {
  }
  .secondColumn {
    margin-top: 40px;
  }
  .button {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .thirdColumn {
    margin-top: 20px;
  }
`

const PackagePage = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 382px;
  height: 1900px;
  border: 1px solid #d9dbe9;
  border-radius: 16px;
  margin-right: 15px;
`

const PackagePageMobile = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 382px;
  height: ${(props) => (props.PackageFree ? '900px' : '1900px')};
  border: 1px solid #d9dbe9;
  border-radius: 16px;
`
const HeaderPackage = styled.p`
  text-align: center;
  font-size: 24px;
  color: #5f2eea;
  padding-top: 30px;
`
const HeaderPackageMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  .PackageFree {
    padding: 20px;
    margin-right: 20px;
  }

  .PackageStarter {
    padding: 20px;
  }
`
const TimePackage = styled.p`
  text-align: center;
  font-size: 20px;
  color: #4e4b66;

  padding-top: 30px;
`
const TimePackageMobile = styled.p`
  text-align: center;
  font-size: 20px;
  color: #4e4b66;
`
const PackageDataHeader = styled.p`
  font-size: 18px;
  color: #5f2eea;
  align-items: center;
  font-weight: bold;
  padding: 0 20px;
`
export default inject(
  'package_app',
  'promotion',
  'payment'
)(observer(PackageDetail))
