import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from 'components/button'
import PackageModal from 'components/modal/PackageModal'
import PackageData from 'components/package_data/PackageData'
import PackageMonth from 'components/package_month/PackageMonth'
import PackageYear from 'components/package_year/PackageYear'
import TabButton from 'components/tab/TabButton'
import { Text } from 'components/typo'
import useMediaQuery from 'hooks/useMediaQuery'
import { message } from 'utils'

import AccountLayout from '../../components/AccountLayout'

const ChangePackage = (props) => {
  const history = useHistory()
  const [selected, setSelect] = useState('trial')
  const [isOpened, setOpen] = useState(false)
  const [packageId, setPackageId] = useState(null)

  const [isSavePass, setIsSavePass] = useState('')
  const isMobile = useMediaQuery('(max-width:900px)')
  const [selectedPackageFree, setSelectedPackagetFree] = useState(true)
  const [selectedPackageStarter, setSelectedPackagetStarter] = useState(false)
  const { headerConfig, rows } = props.package_app.toJS()

  const { column } = headerConfig
  const { user } = props.member.toJS()
  const { user_package } = props.payment.toJS()
  const onClick = (value) => {
    setSelect(value)
  }

  const selectText = () => {
    if (!props.member.isLogin()) {
      return 'เลือก'
    }
    if (
      user.package_type !== 'free' &&
      user_package.package_duration === 'per_month' &&
      isSavePass === 'per_month'
    ) {
      return 'คุณใช้งานแพ็กเกจนี้อยู่'
    }
    if (
      user.package_type !== 'free' &&
      user_package.package_duration === 'per_year' &&
      isSavePass === 'per_year'
    ) {
      return 'คุณใช้งานแพ็กเกจนี้อยู่'
    } else {
      return 'เปลี่ยนแพ็กเกจ'
    }
  }

  useEffect(() => {
    props.package_app.getPackageList({
      topButton: true,
      planPeriod: isSavePass,
    })
  }, [props.package_app, isSavePass])

  const CostPackage = {
    title: <>คุณต้องการเปลี่ยนแพ็กเกจใช่หรือไม่</>,
    body: (
      <>
        แพ็กเกจใหม่จะเริ่มใช้ได้หลังคุณยืนยันการเปลี่ยนแพ็กเกจ
        <br />
        ระบบจะคิดค่าบริการผ่านข้อมูลการชำระเงินที่คุณบันทึกไว้
      </>
    ),
  }

  const onInit = useCallback(async () => {
    try {
      await props.payment.getMyPlan()
      setIsSavePass(user_package.package_duration)
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
  }, [props.payment, user_package.package_duration])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onConfirm = async () => {
    try {
      const change_package = await props.payment.getChangePackage(
        packageId,
        isSavePass
      )
      const { status } = change_package
      if (status === 'no_change') {
        message.error({ message: 'ไม่สามารถเปลี่ยนแพ็กเกจได้' })
        return
      } else if (status === 'pay_more') {
        const path = '/account/my-payment/change-package/payment'
        return history.push(`${path}/${packageId}/${isSavePass}/info`)
      }

      await props.payment.changePackage(packageId, isSavePass, {})
      message.success({ message: 'เปลี่ยนแพ็กเกจสำเร็จ' })
      onInit()
    } catch (e) {
      message.error({ message: e.message })
    }
  }

  return (
    <AccountLayout backTo="/account/my-payment" backText="ข้อมูลการชำระเงิน">
      <PackageModal
        onOk={onConfirm}
        isOpened={isOpened}
        setOpen={setOpen}
        {...CostPackage}
      />
      <Content>
        <Text size="large" bold>
          เปลี่ยนแพ็กเกจการใช้งาน
        </Text>
      </Content>

      {isMobile ? (
        <PackageMobileDiv>
          <PackagePageMobile PackageFree={selectedPackageFree}>
            <HeaderPackageMobile>
              <div className="PackageFree">
                <TabButton
                  key={'trial'}
                  onClick={() => {
                    setSelectedPackagetStarter(false)
                    setSelectedPackagetFree(true)

                    onClick('trial')
                  }}
                  selected={'trial' === selected}
                >
                  แพ็คเก็จฟรี
                </TabButton>
              </div>
              <div className="PackageStarter">
                <TabButton
                  key={'starter'}
                  onClick={() => {
                    setSelectedPackagetFree(false)
                    setSelectedPackagetStarter(true)

                    onClick('starter')
                  }}
                  selected={'starter' === selected}
                >
                  แพ็คเก็จเริ่มต้น
                </TabButton>
              </div>
            </HeaderPackageMobile>
            {selectedPackageFree ? (
              <>
                <TimePackageMobile>100 ข้อมูลรายชื่อลูกค้า</TimePackageMobile>
                <div className="firstColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free === true) {
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <div className="secondColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free === true) {
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <div className="button">
                  <Button
                    large
                    color={
                      user?.package_type === 'free'
                        ? 'secondarydisble'
                        : 'secondary'
                    }
                    text={
                      user?.package_type === 'free'
                        ? 'คุณกำลังใช้งานแพ็กเกจนี้อยู่'
                        : 'เลือก'
                    }
                    width="360px"
                    onClick={() => {
                      user?.package_type !== 'free' && setOpen(true)
                      setPackageId(column[1].id)
                    }}
                  />
                </div>
              </>
            ) : selectedPackageStarter ? (
              <>
                <TimePackageMobile>
                  <b>ไม่จำกัด</b> ข้อมูลรายชื่อลูกค้า
                </TimePackageMobile>
                <div className="firstColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free === true)
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              else if (valueIn.standard === true) {
                                return (
                                  <PackageData key={jndex} color>
                                    {keyIn}
                                  </PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <div className="secondColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free === true)
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              else if (valueIn.standard === true) {
                                return (
                                  <PackageData key={jndex} color>
                                    {keyIn}
                                  </PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <div className="thirdColumn">
                  {Object.entries(rows).map(([key, value], index) => {
                    if (key === 'การทำงานแบบทีม') {
                      return (
                        <div key={index}>
                          <PackageDataHeader primary>{key}</PackageDataHeader>
                          {Object.entries(value).map(
                            ([keyIn, valueIn], jndex) => {
                              if (valueIn.free === true)
                                return (
                                  <PackageData key={jndex}>{keyIn}</PackageData>
                                )
                              else if (valueIn.standard === true) {
                                return (
                                  <PackageData key={jndex} color>
                                    {keyIn}
                                  </PackageData>
                                )
                              }
                              return <></>
                            }
                          )}
                        </div>
                      )
                    }
                    return <></>
                  })}
                </div>
                <PackageYear
                  isSavePass={isSavePass}
                  setIsSavePass={setIsSavePass}
                  defaultPackage={
                    user.package_type !== 'free' &&
                    user_package.package_duration === 'per_year'
                      ? true
                      : false
                  }
                />
                <PackageMonth
                  isSavePass={isSavePass}
                  setIsSavePass={setIsSavePass}
                  defaultPackage={
                    user.package_type !== 'free' &&
                    user_package.package_duration === 'per_month'
                      ? true
                      : false
                  }
                />
                <div className="button">
                  <Button
                    large
                    color="secondary"
                    text={selectText()}
                    width="360px"
                    onClick={() => {
                      setOpen(true)
                      setPackageId(column[2].id)
                    }}
                    disabled={
                      (user.package_type !== 'free' &&
                        user_package.package_duration === 'per_month' &&
                        isSavePass === 'per_month') ||
                      (user.package_type !== 'free' &&
                        user_package.package_duration === 'per_year' &&
                        isSavePass === 'per_year')
                        ? true
                        : false
                    }
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </PackagePageMobile>
        </PackageMobileDiv>
      ) : (
        <PackageDiv>
          <PackagePage>
            <HeaderPackage>แพ็กเกจฟรี</HeaderPackage>
            <TimePackage>100 ข้อมูลรายชื่อลูกค้า</TimePackage>
            <div className="firstColumn">
              {Object.entries(rows).map(([key, value], index) => {
                if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                  return (
                    <div key={index}>
                      <PackageDataHeader primary>{key}</PackageDataHeader>
                      {Object.entries(value).map(([keyIn, valueIn], jndex) => {
                        if (valueIn.free === true) {
                          return <PackageData key={jndex}>{keyIn}</PackageData>
                        }
                        return <></>
                      })}
                    </div>
                  )
                }
                return <></>
              })}
            </div>
            <div className="secondColumn">
              {Object.entries(rows).map(([key, value], index) => {
                if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                  return (
                    <div key={index}>
                      <PackageDataHeader primary>{key}</PackageDataHeader>
                      {Object.entries(value).map(([keyIn, valueIn], jndex) => {
                        if (valueIn.free === true) {
                          return <PackageData key={jndex}>{keyIn}</PackageData>
                        }
                        return <></>
                      })}
                    </div>
                  )
                }
                return <></>
              })}
            </div>
            <div className="button">
              <Button
                large
                color={
                  user?.package_type === 'free'
                    ? 'secondarydisble'
                    : 'secondary'
                }
                text={
                  user?.package_type === 'free'
                    ? 'คุณกำลังใช้งานแพ็กเกจนี้อยู่'
                    : 'เลือก'
                }
                width="360px"
                onClick={() => {
                  user?.package_type !== 'free' && setOpen(true)
                  setPackageId(column[1].id)
                }}
              />
            </div>
          </PackagePage>
          <PackagePage>
            <HeaderPackage>แพ็กเกจเริ่มต้น</HeaderPackage>
            <TimePackage>
              <b>ไม่จำกัด</b> ข้อมูลรายชื่อลูกค้า
            </TimePackage>
            <div className="firstColumn">
              {Object.entries(rows).map(([key, value], index) => {
                if (key === 'การดูแลลูกค้าและสร้างความสัมพันธ์') {
                  return (
                    <div key={index}>
                      <PackageDataHeader primary>{key}</PackageDataHeader>
                      {Object.entries(value).map(([keyIn, valueIn], jndex) => {
                        if (valueIn.free === true)
                          return <PackageData key={jndex}>{keyIn}</PackageData>
                        else if (valueIn.standard === true) {
                          return (
                            <PackageData key={jndex} color>
                              {keyIn}
                            </PackageData>
                          )
                        }
                        return <></>
                      })}
                    </div>
                  )
                }
                return <></>
              })}
            </div>
            <div className="secondColumn">
              {Object.entries(rows).map(([key, value], index) => {
                if (key === 'การวางแผนการเงินการให้คำปรึกษา') {
                  return (
                    <div key={index}>
                      <PackageDataHeader primary>{key}</PackageDataHeader>
                      {Object.entries(value).map(([keyIn, valueIn], jndex) => {
                        if (valueIn.free === true)
                          return <PackageData key={jndex}>{keyIn}</PackageData>
                        else if (valueIn.standard === true) {
                          return (
                            <PackageData key={jndex} color>
                              {keyIn}
                            </PackageData>
                          )
                        }
                        return <></>
                      })}
                    </div>
                  )
                }
                return <></>
              })}
            </div>
            <div className="thirdColumn">
              {Object.entries(rows).map(([key, value], index) => {
                if (key === 'การทำงานแบบทีม') {
                  return (
                    <div key={index}>
                      <PackageDataHeader primary>{key}</PackageDataHeader>
                      {Object.entries(value).map(([keyIn, valueIn], jndex) => {
                        if (valueIn.free === true)
                          return <PackageData key={jndex}>{keyIn}</PackageData>
                        else if (valueIn.standard === true) {
                          return (
                            <PackageData key={jndex} color>
                              {keyIn}
                            </PackageData>
                          )
                        }
                        return <></>
                      })}
                    </div>
                  )
                }
                return <></>
              })}
            </div>
            <PackageYear
              isSavePass={isSavePass}
              setIsSavePass={setIsSavePass}
              defaultPackage={
                user.package_type !== 'free' &&
                user_package.package_duration === 'per_year'
                  ? true
                  : false
              }
            />
            <PackageMonth
              isSavePass={isSavePass}
              setIsSavePass={setIsSavePass}
              defaultPackage={
                user.package_type !== 'free' &&
                user_package.package_duration === 'per_month'
                  ? true
                  : false
              }
            />
            <div className="button">
              <Button
                large
                color="secondary"
                text={selectText()}
                width="360px"
                onClick={() => {
                  setOpen(true)
                  setPackageId(column[2].id)
                }}
                disabled={
                  (user.package_type !== 'free' &&
                    user_package.package_duration === 'per_month' &&
                    isSavePass === 'per_month') ||
                  (user.package_type !== 'free' &&
                    user_package.package_duration === 'per_year' &&
                    isSavePass === 'per_year')
                    ? true
                    : false
                }
              />
            </div>
          </PackagePage>
        </PackageDiv>
      )}
    </AccountLayout>
  )
}

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  width: 325px;
  margin-bottom: 16px;
`

const PackageDiv = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
  .firstColumn {
    max-height: 500px;
    height: 500px;
  }
  .secondColumn {
    margin-top: 40px;
  }
  .button {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .thirdColumn {
    margin-top: 20px;
  }
`
const PackagePage = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 382px;
  height: 1900px;
  border: 1px solid #d9dbe9;
  border-radius: 16px;
  margin-right: 15px;
`
const HeaderPackage = styled.p`
  text-align: center;
  font-size: 24px;
  color: #5f2eea;
  padding-top: 30px;
`
const TimePackage = styled.p`
  text-align: center;
  font-size: 20px;
  color: #4e4b66;

  padding-top: 30px;
`
const PackageDataHeader = styled.p`
  font-size: 18px;
  color: #5f2eea;
  align-items: center;

  font-weight: bold;
  padding: 0 20px;
`
const PackagePageMobile = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 382px;
  height: ${(props) => (props.PackageFree ? '900px' : '1900px')};
  border: 1px solid #d9dbe9;
  border-radius: 16px;
`

const PackageMobileDiv = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
  .firstColumn {
  }
  .secondColumn {
    margin-top: 40px;
  }
  .button {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .thirdColumn {
    margin-top: 20px;
  }
`

const HeaderPackageMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  .PackageFree {
    padding: 20px;
    margin-right: 20px;
  }

  .PackageStarter {
    padding: 20px;
  }
`
const TimePackageMobile = styled.p`
  text-align: center;
  font-size: 20px;
  color: #4e4b66;
`
export default inject(
  'member',
  'payment',
  'package_app'
)(observer(ChangePackage))
