import styled from 'styled-components'

import Countdown from './Countdown'

function Header() {
  return (
    <Container>
      <CountdownWrapper>
        <Topic>รีบด่วน! โปรโมชั่นจะจบใน</Topic>
        <Countdown bgColor="white" />
      </CountdownWrapper>
    </Container>
  )
}

export default Header

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 35px;
  background: linear-gradient(180deg, #340bb4 25.26%, #6035e7 64.37%);
  overflow: hidden;

  @media screen and (max-width: 550px) {
    padding: 50px 35px;
  }
`
const CountdownWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  .Timer {
    background-color: white !important;
    border-radius: 72px !important;
    color: #5f2eea;
    div {
      color: #5f2eea;
    }
  }
  button {
    span {
      font-size: 24px;
    }
  }
`
const Topic = styled.div`
  margin-bottom: 24px;
  text-align: center;
  font-size: 64px;
  font-weight: bold;
  line-height: 66px;
  color: #fdc851;
`
