import { inject, observer } from 'mobx-react'
import { useState } from 'react'
import styled from 'styled-components'

import { UserImg } from 'components/display'
import { Link } from 'components/link'
import useGTM from 'hooks/useGTM'
import useMediaQuery from 'hooks/useMediaQuery'
import { Logo } from 'icon'
import Burger from 'icon/Burger'
import Close from 'icon/Close'

import UserMenu from './UserMenu'
import UserMenuMobile from './UserMenuMobile'

const HeaderMenu = (props) => {
  const user = props.member.getCurrentUser()

  const [isOpen, setOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width:768px)')
  const { onEvent } = useGTM()

  const toggleMenu = () => setOpen((open) => !open)

  const onLogoClick = () => {
    onEvent({ eventName: 'friday-icon-click' })
  }

  const onLoginClick = () => {
    onEvent({ eventName: 'friday-login-click' })
  }

  if (isMobile) {
    return (
      <TopBar id="app_header">
        <MobileInnerTopBar>
          <InnerLeft>
            <BurgerButton onClick={toggleMenu}>
              {isOpen ? <Close /> : <Burger />}
            </BurgerButton>
            <Link to="/" onClick={onLogoClick}>
              <LogoSection>
                <Logo />
              </LogoSection>
            </Link>
          </InnerLeft>
          {user ? (
            <Link to={'/account/profile'}>
              <UserImg src={user.img_url} gender={user.gender} size={40} />
            </Link>
          ) : (
            <Link
              to="/login"
              onClick={onLoginClick}
              style={{ color: '#5f2eea', fontWeight: 'bold' }}
            >
              เข้าสู่ระบบ
            </Link>
          )}
        </MobileInnerTopBar>
        <UserMenuMobile isOpen={isOpen} onClick={toggleMenu} />
      </TopBar>
    )
  }

  return (
    <TopBar id="app_header">
      <InnerTopBar>
        <Link to="/" onClick={onLogoClick}>
          <LogoSection>
            <Logo />
          </LogoSection>
        </Link>
        <UserMenu />
      </InnerTopBar>
    </TopBar>
  )
}

const BurgerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
`

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`

const InnerTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 70px;
  padding: 0 70px;
  width: 100%;
  max-width: 1440px;

  z-index: 20;

  @media screen and (max-width: 800px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 568px) {
    padding: 0 16px;
  }
`

const MobileInnerTopBar = styled(InnerTopBar)`
  justify-content: space-between;
`

const InnerLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;

  width: 100vw;

  position: fixed;

  background-color: white;
  border-bottom: 1px solid ${(props) => props.theme.gray.inputBackground};
`

export default inject('member')(observer(HeaderMenu))
